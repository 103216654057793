import { render, staticRenderFns } from "./ResourceStatus.vue?vue&type=template&id=22f8a554&scoped=true&"
import script from "./ResourceStatus.vue?vue&type=script&lang=js&"
export * from "./ResourceStatus.vue?vue&type=script&lang=js&"
import style0 from "./ResourceStatus.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ResourceStatus.vue?vue&type=style&index=1&id=22f8a554&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f8a554",
  null
  
)

export default component.exports