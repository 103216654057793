<template>
  <div>
    <Breadcrumb />
    <ModalAddResource
      @refetch-data="getStatus"
      :showToast="showToast"
      :classificationList="classificationListLanguage"
      :nameStatus="nameStatus"
    />
    <ModalEditResource
      :currentlyStatusId="currentlyStatusId"
      @refetch-data="getStatus"
      :classificationList="classificationListLanguage"
      :nameStatus="nameStatus"
      :allowEdit="allowEdit"
    />
    <ModalDeleteResource
      :currentlyStatusId="currentlyStatusId"
      :showToast="showToast"
      @refetch-data="getStatus"
      :allowEdit="allowEdit"
    />
    <b-card id="card-resource-status">
      <div>
        <div class="d-flex align-items-center justify-content-start c-header">
          <b-form-group class="w-100">
            <div
              class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between"
            >
              <span class="c-title">{{ $t('ResourceStatus.Title') }}</span>
              <b-button
                class="btn-new-reason"
                v-b-modal.modal-add-resource
                @click="setForceRender"
              >
                <span class="text-nowrap">{{ $t('ResourceStatus.Add') }}</span>
              </b-button>
            </div>
          </b-form-group>
        </div>

        <table-default
          :rows="rows"
          :fields="fields"
          :stickyHeader="false"
          :striped="true"
          :sizeSearch="12"
          id="table-resource-status"
        >
          <template v-slot:cell(action)="data">
            <span
              v-if="data.item.allow_edit == 0"
              class="d-flex justify-content-center"
              >-</span
            >
            <span
              v-if="data.item.allow_edit == 1"
              class="d-flex justify-content-center"
            >
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="d-action-reasons"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="d-color align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-edit-resource
                  @click="editResourceStatus(data.item.id, data.item.has_operation_progress)"
                >
                  <EditIcon />
                  <span class="d-itens m-1">
                    {{ $t('ResourceStatus.Edit') }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-delete-resource
                  @click="editResourceStatus(data.item.id, data.item.has_operation_progress)"
                >
                  <DeleteIcon />
                  <span class="d-itens ml-1">
                    {{ $t('ResourceStatus.Delete') }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
          <template v-slot:cell(description)="data">
            <span :id="`tooltip-resource-description-${data.index}`">
              {{ data.item.description }}
            </span>
            <b-tooltip
              custom-class="tooltip-resource-status"
              triggers="hover blur"
              :target="`tooltip-resource-description-${data.index}`"
              positioning="top"
              placement="top"
            >
              <div class="info-tooltip-resource-status">
                {{ data.item.description }}
              </div>
            </b-tooltip>
          </template>
          <template v-slot:cell(parada)="data">
            <span>
              {{ data.item.status_classification_name }}
            </span>
          </template>
          <template v-slot:cell(color)="data">
            <b-row class="d-flex justify-content-start flex-wrap">
              <div
                :style="getColor(data.item.color)"
                class="text-center colors-container rounded d-flex align-items-center justify-content-center shadow"
              ></div>
              <div
                class="d-flex align-items-center"
                style="margin-left: 10px"
              >
                <span>
                  {{ data.item.color }}
                </span>
              </div>
            </b-row>
          </template>
        </table-default>

        <div class="demo-spacing-0">
          <b-alert
            variant="primary"
            :show="flagShowOverlay == false && rows.length == 0 ? true : false"
          >
            <div class="alert-body">
              <span>Nenhum resultado encontrado.</span>
            </div>
          </b-alert>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import Breadcrumb from '@/@core/components/breadcrumb';
  import {
    BRow,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    BTooltip,
    VBToggle,
    BButton
  } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import store from '@/store/index';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import BTable from '@core/components/b-table/BTable.vue';
  import TableDefault from '@core/components/table-default/TableDefault.vue';
  import DeleteIcon from '@/assets/images/pages/trash-icon-grey.svg';
  import EditIcon from '@/assets/images/pages/edit-icon-grey.svg';
  import ModalAddResource from '@/@core/components/b-modal/ModalAddResource.vue';
  import ModalEditResource from '@/@core/components/b-modal/ModalEditResource.vue';
  import ModalDeleteResource from '@/@core/components/b-modal/ModalDeleteResource.vue';
  import { mapMutations, mapGetters } from 'vuex';
  export default {
    components: {
      BRow,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BAlert,
      BDropdown,
      BDropdownItem,
      BCard,
      ToastificationContent,
      BButton,
      BTooltip,
      Breadcrumb,
      BTable,
      TableDefault,
      EditIcon,
      DeleteIcon,
      ModalAddResource,
      ModalEditResource,
      ModalDeleteResource
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        pageLength: 10,
        dir: false,
        currentlyStatusId: '',
        classificationList: [
          { text: 'Selecione', value: 0, language: 'PT' },

          { text: 'Select', value: 0, language: 'EN' }
        ],
        classificationListLanguage: [],
        fields: [
          {
            label: 'ResourceStatus.Action',
            key: 'action'
          },
          {
            label: 'ResourceStatus.Name',
            key: 'name',
            sortable: true
          },
          {
            label: 'ResourceStatus.Description',
            key: 'description',
            sortable: true
          },
          {
            label: 'ResourceStatus.Stop',
            key: 'parada',
            sortable: true
          },
          {
            label: 'ResourceStatus.Color',
            key: 'color'
          }
        ],
        rows: [],
        allowEdit: false,
        searchTerm: '',
        nameStatus: []
      };
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      setCurrentlyStatusId(id) {
        this.currentlyStatusId = id;
        this.setForceRender();
      },
      editResourceStatus(id, allowEdit) {
        this.setCurrentlyStatusId(id);
        this.allowEdit = allowEdit == 0 ? false : true;
      },
      setForceRender() {
        this.classificationListLanguage = this.classificationList.filter(
          (el) => el.language == localStorage.getItem('language').toUpperCase()
        );
      },
      getStatusClassification() {
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/status-classification?site_guid=${this.currentSite.guid}`
          )
          .then((response) =>
            response.data.response.forEach((el) => {
              this.classificationList.push({
                text: el.name,
                value: el.code,
                language: el.language
              });
            })
          )
          .catch((error) =>
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
          );
      },
      getStatus() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/currently-status?site_guid=${this.currentSite.guid}`
          )
          .then((res) => {
            this.rows = res.data.response;
            this.nameStatus = res.data.response.map((item) => item.name);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      getColor(color) {
        return `background-color:${color}; margin-left:10px; height: 30px; width: 30px`;
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      }
    },
    computed: {
      ...mapGetters('app', ['flagShowOverlay']),
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true;
          return this.dir;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      }
    },
    created() {
      this.getStatus();
      this.getStatusClassification();
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-good-table.scss';
  @import '~@core/scss/vue/libs/vue-sweetalert.scss';

  .tooltip-resource-status {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;
    .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-resource-status {
        max-width: 339px;
        padding: 7px 8px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        letter-spacing: 0.16px;
        border: 1px solid transparent;
      }
    }

    .arrow {
      &::before {
        border-top-color: #323232;
        border-bottom-color: #323232;
      }
    }
  }

  .overlay-mensage {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    .tooltip-resource-status {
      .tooltip-inner {
        .info-tooltip-resource-status {
          max-width: 288px !important;
        }
      }
    }

    #card-resource-status {
      .c-header {
        .btn-new-reason {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }

  #card-resource-status {
    overflow: hidden;
    .c-header {
      .c-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #4c4541 !important;
      }

      .btn-new-reason {
        background: #974900 !important;
        border: none;
        padding: 4px 14px;
        span {
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.0125em;
        }
      }
    }

    #table-resource-status {
      overflow: auto !important;

      table {
        thead th {
          padding: 10.5px 9px !important;
        }

        th:nth-child(1) {
          padding: 10.5px 7.65px !important;
        }

        th {
          padding: 11px 9px !important;
        }

        td {
          padding: 13.5px 10px !important;
          height: 38px !important;
          letter-spacing: 0;
          border: 1px solid #e0e0e0 !important;
          border-bottom: none !important;
          border-left: none !important;
          border-right: none !important;
        }

        tbody tr td,
        thead tr th {
          outline: none !important;
          border-left: none !important;
          border-right: none !important;
        }

        tbody tr td:nth-child(1),
        thead tr th:nth-child(1) {
          min-width: 57px !important;
        }

        tbody tr td:nth-child(2),
        thead tr th:nth-child(2) {
          width: 7%;
          min-width: 167px;
        }

        tbody tr td:nth-child(3),
        thead tr th:nth-child(3) {
          width: 74%;
          min-width: 617px;
        }

        tbody tr td:nth-child(4),
        thead tr th:nth-child(4) {
          width: 2%;
          min-width: 201px;
        }

        tbody tr td:nth-child(5),
        thead tr th:nth-child(5) {
          width: 2%;
          min-width: 143px;
        }

        thead tr th {
          background-color: rgba(151, 73, 0, 0.05) !important;
        }
        thead tr th div {
          text-transform: uppercase;
          font-size: 12px;
        }

        .d-action-reasons {
          button {
            width: 32px;
            height: 28px;
            padding: 0;
            svg circle {
              color: #974900 !important;
            }
          }
          .dropdown-item {
            &:hover {
              background-color: #fbeee8;
              svg path {
                fill: #974900 !important;
              }
              span {
                color: #974900 !important;
              }
            }
          }

          button:hover {
            background-color: #ffdbc4 !important;
          }

          svg {
            margin: 0 !important;
          }
        }

        .container-block-color {
          .block-color {
            width: 25px;
            height: 25px;
            background-color: #000;
            border-radius: 6px;
            margin-right: 8px;
          }
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';

  .dark-layout {
    div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
    }
  }
</style>
